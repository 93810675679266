<footer>
    <div class="container">
        <div class="row row-div">
            <!-- <div class=" div-col col-lg-3 d-flex align-items-center">
                <div class="footer-logo">
                    <a [routerLink]="['/']">
                        <img src="assets/images/logopng.png" alt="footer-logo">
                    </a>
                </div>
            </div> -->
            <div class=" div-col col-lg-4">
                <div class="address-box">
                    <p>34, Amrutpark Society, Visnagar link Road, Mehsana 384001, Gujarat, India.</p>
                    <p> <a href="mailto:info@skycrapercrs.com" class="email" > bd@skycrapercrs.in </a> </p>
                    <p> <a href="tel:9586974089" class="phone">(+91) 9586974089 </a> </p>
                </div>
            </div>
            <div class=" div-col col-lg-5">
                <div class="footer-menu d-flex flex-wrap align-items-center">
                    <ul class="menu-list" >
                        <li> <a [routerLink]="['/']" routerLinkActive="active-link"> Home </a> </li>
                        <li> <a  [routerLink]="['/our_business']" routerLinkActive="active-link"> Our Business Area </a> </li>
                        <li> <a  [routerLink]="['/service']" routerLinkActive="active-link">  Services  </a> </li>
                        <li> <a  [routerLink]="['/achievement']" routerLinkActive="active-link"> Achievement </a> </li>
                        <li> <a  [routerLink]="['/event']" routerLinkActive="active-link"> Event </a> </li>
                        <li> <a  [routerLink]="['/about']" routerLinkActive="active-link"> About Us </a> </li>
                         <li> <a  [routerLink]="['/career']" routerLinkActive="active-link"> Career </a> </li>
                    </ul>
                    <!-- <div class="w-100" >
                        <form>
                            <div class="form-grp" >
                                <input type="text" placeholder="Email" >
                                <button type="button" class="btn">Submit</button>
                            </div>
                        </form>
                    </div> -->
                    <!-- <button type="button" class="btn">contact Us</button> -->
                </div>
            </div>
        </div>
    </div>
    <div class="bottom-footer">
        <div class="container">
            <div class="row">
                <div class="col-md-6">
                    <strong>© 2023 SkyCrapercrs. All Right Reserved</strong>
                </div>
                <div class="col-md-6">
                    <ul class="menu-list" >
                        <li> <a href="https://www.linkedin.com/company/skycraper-clinical-research-solutions/"> <img src="assets/images/linkedin.svg" alt="social-icon" title="facebook" > </a> </li>
                        <!-- <li> <a href="#"> Achievement </a> </li>
                        <li> <a href="#">  Services  </a> </li> -->
                    </ul>
                </div>
            </div>
        </div>
    </div>
</footer>