import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { CommonModule, } from '@angular/common';
import { BrowserModule } from '@angular/platform-browser';

const routes: Routes = [

  {
    path: '',
    // redirectTo: '/home',
    // pathMatch: 'full',
    children: [
      {
        path: '',
        loadChildren: () => import('./pages/skyscraper/skyscraper.module').then(m => m.SkyscraperModule)
      },
      {
        path: 'event',
        loadChildren: () => import('./pages/event/event.module').then(m => m.EventModule)
      },
      {
        path: 'about',
        loadChildren: () => import('./pages/aboutus/aboutus.module').then(m => m.AboutusModule)
      },
      {
        path: 'services',
        loadChildren: () => import('./pages/service/service.module').then(m => m.ServiceModule)
      },
      {
        path: 'our_business',
        loadChildren: () => import('./pages/therapeutic/therapeutic.module').then(m => m.TherapeuticModule)
      },
      // {
      //   path: 'achievement',
      //   loadChildren: () => import('./pages/achievement/achievement.module').then(m => m.AchievementModule)
      // },
      {
        path: 'career',
        loadChildren: () => import('./pages/career/career.module').then(m => m.CareerModule)
      },
    ]
  },
];

@NgModule({
  imports: [
    CommonModule,
    BrowserModule,
    RouterModule.forRoot(routes, {
      // useHash: true,
      scrollPositionRestoration: 'top'
    })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
